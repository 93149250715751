import api from "./index"

/**
 * Get all posts
 *
 * @param params 
 * @param includes
 * @return {*}
 */
export const getAllPostsRequest = (params, includes = []) => {
  return api
    .request("admin/post")
    .withParams(params)
    .withIncludes(includes)
    .get()
}

/**
 * Get all Need to approve posts
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getNeedApprovePostsRequest = (params, includes = []) => {
  return api
    .request("admin/post/waiting")
    .withParams(params)
    .withIncludes(includes)
    .get()
}

/**
 * Get all archive posts
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getArchivePostsRequest = (params, includes = []) => {
  return api
    .request("admin/post/archive")
    .withParams(params)
    .withIncludes(includes)
    .get()
}

/**
 * Get all reported posts
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getReportedPostsRequest = (params, includes = []) => {
  return api
    .request("admin/post/reported")
    .withParams(params)
    .withIncludes(includes)
    .get()
}

/**
 * Get all removal posts
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getRemovalPostsRequest = (params, includes = []) => {
  return api
    .request("admin/post/removal")
    .withParams(params)
    .withIncludes(includes)
    .get()
}
